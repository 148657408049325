// import React from "react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Logo from "../assets/images/header/logo.png";
const Header = () => {
  let value = typeof window !== "undefined" && window.pageYOffset;
  const [position, setPosition] = useState(value);
  const [visible, setVisible] = useState(true);

  const BeforeDesktop = ({ children }) => {
    const isBeforeDesktop = useMediaQuery({ maxWidth: 991.98 });
    return isBeforeDesktop ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };

  const handldeOverlayActive = () => {
    document.body.classList.toggle("active-nav-overlay");
    document.body.classList.toggle("vh-100");
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  // TO FIND SCROLL Y POSITION
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  // THIS USEFFECT GIVE US POSITION OF SCROLL IN EVERY PIXELS WE SCROLL
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // THIS USEFFECT GIVE US POSITION OF SCROLL IN EVERY PIXELS WE SCROLL
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const [headerVisible, setHeaderVisible] = useState(false);

  useLayoutEffect(() => {
    const scrollEvent = document.addEventListener("scroll", () => {
      if (window.pageYOffset > 800) {
        setHeaderVisible(true);
        return;
      }
      if (window.pageYOffset < 800) {
        setHeaderVisible(false);
        return;
      }
    });

    return () => {
      document.removeEventListener("scroll", scrollEvent);
    };
  }, []);
  return (
    <>
      <div
        className={`position-fixed top_0 start-0 end-0 fixed_navbar ${
          visible ? "" : "navbar-show__hide"
        }`}
      >
        <div
          className={`pt-xl-4 ${
            headerVisible && "gradient_navbar"
          } navbar-wrapper w-100 position-relative z-5`}
        >
          <div className={`container`}>
            <Desktop>
              <div className="d-flex justify-content-between align-items-center w-100">
                <a className="mx-width105" href="/">
                  <img className="w-100" src={Logo} alt="Logo" />
                </a>
                <div className="nav-list-items">
                  <ul className="list-unstyled d-flex align-items-center mb-0">
                    <li>
                      <a className="navbar-link fw_bold" href="#testimonys">
                        Testimonys
                      </a>
                    </li>
                    <li className="ms-4 ms-xl-5">
                      <a className="navbar-link fw_bold" href="#modules">
                        Modules
                      </a>
                    </li>
                    <li className="ms-4 ms-xl-5">
                      <a className="navbar-link fw_bold" href="#programs">
                        Programs
                      </a>
                    </li>
                    <li className="ms-4 ms-xl-5">
                      <a className="navbar-link fw_bold" href="#bonuses">
                        Bonuses
                      </a>
                    </li>
                    <li className="ms-4 ms-xl-5">
                      <a className="navbar-link fw_bold" href="#about">
                        About
                      </a>
                    </li>
                    <li className="ms-4 ms-xl-5">
                      <a className="navbar-link fw_bold" href="#my_masters">
                        My Masters
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Desktop>
            <BeforeDesktop>
              <div className="d-flex justify-content-between align-items-center flex-row-reverse">
                <div className="nav-log cursor_pointer z-index_100">
                  <img src={Logo} alt="" />
                </div>
                <div
                  id="nav-icon1"
                  className={`hamburger-menu`}
                  onClick={() => handldeOverlayActive()}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              <div className={`navbar-overlay `}>
                <ul className="h-100 list-unstyled d-flex flex-column align-items-center justify-content-center m-0">
                  <li className="pb-3" onClick={() => handldeOverlayActive()}>
                    <a className="navbar-link fw_bold" href="#testimonys">
                      Testimonys
                    </a>
                  </li>
                  <li className="py-3" onClick={() => handldeOverlayActive()}>
                    <a className="navbar-link fw_bold" href="#modules">
                      Modules
                    </a>
                  </li>
                  <li className="py-3" onClick={() => handldeOverlayActive()}>
                    <a className="navbar-link fw_bold" href="#programs">
                      Programs
                    </a>
                  </li>
                  <li className="py-3" onClick={() => handldeOverlayActive()}>
                    <a className="navbar-link fw_bold" href="#bonuses">
                      Bonuses
                    </a>
                  </li>
                  <li className="py-3" onClick={() => handldeOverlayActive()}>
                    <a className="navbar-link fw_bold" href="#about">
                      About
                    </a>
                  </li>
                  <li className="py-3" onClick={() => handldeOverlayActive()}>
                    <a className="navbar-link fw_bold" href="#my_masters">
                      My Masters
                    </a>
                  </li>
                  <li className="pt-3" onClick={() => handldeOverlayActive()}>
                    <a
                      className="navbar-link fw_bold Slider_btn px-5 py-3"
                      href="/checkout"
                    >
                      Checkout
                    </a>
                  </li>
                </ul>
              </div>
            </BeforeDesktop>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
