import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { ModuleClickBtn, ModuleSectionLeaf } from "./common/Icons";
import { moduleData } from "./Helper";
import { useInView } from "react-intersection-observer";
const ModuleSlider = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const sliderRef2 = useRef(null);

  useEffect(() => {
    if (inView) {
      sliderRef2.current.slickPlay();
    } else {
      sliderRef2.current.slickPause();
    }
  }, [inView]);

  const [leftMargin, setLeftMargin] = useState(0);
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    if (containerRef.current) {
      setLeftMargin(containerRef.current.getBoundingClientRect().left);
    }
    window.addEventListener("resize", () => {
      if (containerRef.current) {
        setLeftMargin(containerRef.current.getBoundingClientRect().left);
      }
    });
  }, [leftMargin]);

  useEffect(() => {
    window.addEventListener("wheel", scroll, true);
    return () => {
      window.removeEventListener("wheel", scroll, true);
    };
  }, []);

  const settings = {
    dots: false,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3.1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section id="modules" ref={ref}>
        <div className="container mt-5" ref={containerRef}>
          <h2 className="heading mb-5 pb-4 pb-lg-5 text-center">
            What's Included
          </h2>
          <div
            className="d-flex align-items-center module_section_heading mb-1 mb-md-4 pb-3"
            data-aos="fade-in"
          >
            <ModuleSectionLeaf />
            <div className="ps-4">
              <h2 className="heading mb-0 mb-md-2">Video course</h2>
              <p className="para white opacity-75 mb-0 py-2">
                13 Modules, 100+ videos
              </p>
            </div>
          </div>
        </div>
        <div
          className="mb-md-5 pb-5 slider_container"
          style={{ marginLeft: `${leftMargin + 16}px` }}
          data-aos="fade-out"
        >
          <Slider {...settings} ref={sliderRef2}>
            {moduleData.map((obj, index) => (
              <div className="px-2" key={index}>
                {/* <div className="position-relative module_card"> */}
                <div
                  className={
                    index === 13
                      ? "position-relative"
                      : "position-relative module_card"
                  }
                >
                  <div className="position-absolute end-0 top-0 pt-xl-3 pe-2 click_hover_module">
                    <ModuleClickBtn />
                  </div>
                  <div className="position-absolute module_hover_card w-100 h-100 start-0 end-0 opacity-0 transition_03s">
                    <div className="position-fixed w-100 start-0 end-0 top-0 module_card_top_heading d-flex align-items-center">
                      <div className="position-absolute start-0 top-0 pt-3 ps-3 ps-sm-1">
                        <ModuleSectionLeaf />
                      </div>

                      <p className="font-md fw_bold white text-center text-uppercase module_card_heading py-2 ">
                        {obj.heading}
                      </p>
                    </div>
                    <div className="module_card_para">
                      <p className="font-xsm white fw_bold mt-5 mb-3">
                        {obj.modulePara1}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara2}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara3}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara4}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara5}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara6}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara7}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara8}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara9}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara10}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara11}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara12}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara13}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara14}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara15}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara16}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara17}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara18}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara19}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara20}
                      </p>
                      <p className="font-xsm white fw_bold mb-3">
                        {obj.modulePara21}
                      </p>
                    </div>
                  </div>
                  <div className="position-relative">
                    <div className="position-absolute  refresh-icon d-sm-none">
                      {obj.moduleIcon && (
                        <img src={obj.moduleIcon} alt="moduleImg" />
                      )}
                    </div>
                    <img
                      className="w-100 module_main_img "
                      src={obj.moduleImg}
                      alt="moduleImg"
                    />

                    <div className="position-absolute start-0 bottom-0 pt-3 ps-3 ">
                      <p className="module_text fw_bold font-4xl text-uppercase ps-1">
                        {obj.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default ModuleSlider;
