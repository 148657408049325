import React from "react";
import loaderGif from "../../assets/videos/subs rev1(1).gif";
const Loader = () => {
  return (
    <div className="loader-fullscreen w-100 vh-100 top-0 start-0 d-flex justify-content-center align-items-center position-fixed  color-light-green">
      <img src={loaderGif} alt="" />
    </div>
  );
};

export default Loader;
