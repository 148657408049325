import React from "react";
// import muscle from "../assets/images/Trainer/muscle-img.png";
import { TrainerMuscle } from "./common/Icons";
import { trainerData } from "./Helper";
import { Bonusesshopimg } from "./common/Icons";

const Trainer = () => {
  return (
    <>
      <section
        className="pt-3 pt-md-5 mt-md-5 mt-xxl-4 mb-1 mb-md-0"
        id="my_masters"
      >
        <div className="container pt-1">
          <div
            className="d-flex flex-column flex-sm-row align-items-md-center pb-sm-5 mb-xl-4"
            data-aos="fade-in"
          >
            <div className="pe-3 pb-2 mb-1 pb-sm-0 ">
              <TrainerMuscle />
            </div>
            <div className="col-lg-11">
              <h4 className="heading fw_bold text-uppercase">
                Master Science BAsed Training Indviulas Noah Has Learned From +
                Worked With
              </h4>
            </div>
          </div>
          <div className="row justify-content-center justify-content-lg-start">
            {trainerData.map((val, index) => {
              return (
                <div
                  className={`col-sm-12 col-lg-6 pb-md-5 pb-4 mb-2 mb-md-2 ${
                    index === 0
                      ? "order-0"
                      : `order-lg-1 ${
                          index === 1
                            ? "order-1"
                            : `order-lg-2 ${
                                index === 2
                                  ? "order-0"
                                  : `order-lg-3 ${
                                      index === 4
                                        ? "order-0"
                                        : `order-lg-3 ${
                                            index === 3
                                              ? "order-2"
                                              : `order-lg-5`
                                          }`
                                    }`
                              }`
                        }`
                  }`}
                  key={index}
                  data-aos="fade-out"
                >
                  <div
                    Healers
                    className={`d-flex  justify-content-center justify-content-sm-evenly justify-content-lg-start`}
                  >
                    <div
                      className={`trainers-img ${
                        index === 1 || index === 2 ? "order_2" : "order_1"
                      }`}
                    >
                      <img
                        className="w-100 border-radius50 trainer-img"
                        src={val.imgUrl}
                        alt=""
                      />
                    </div>
                    <div
                      className={`ps-3 ps-xl-4 ${
                        index === 1 || index === 2
                          ? "order_1 pe-3 pe-md-4 pe-lg-0 pt-2"
                          : "order_2 ps-3 ps-md-4 ps-lg-3 pt-2"
                      }`}
                    >
                      <h2 className="font-lg sky-blue fw_bold text-uppercase pb-1">
                        {val.heading}
                      </h2>
                      {val.para.map((obj, index) => (
                        <p
                          className={`sub_para mb-0 white  text-uppercase ${
                            obj.text ==
                            "Coached 100s of elitle level powerlifters"
                              ? "pt-1 pt-md-2 pb-4 pe-xl-5"
                              : ""
                          }`}
                          key={index}
                        >
                          {obj.text}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row py-4 my-3 my-sm-0 py-sm-5">
            <div className="col-12 text-center">
              <a
                className="text-decoration-none buy_now_btn position-relative d-inline-block text-center mt-1 mt-sm-0"
                href="https://flemfit.com/checkout"
              >
                <div className="position-absolute start-0 ms-3 ms-sm-4 buy_now_icon">
                  <Bonusesshopimg />
                </div>
                BUY NOW
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trainer;
