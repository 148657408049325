import React from "react";
import Header from "../components/Header";
import heroimg1 from "../assets/images/hero/hero_rect_before_img.png";
import heroimg2 from "../assets/images/hero/hero_rect_after_img.png";
import heroimg3 from "../assets/images/hero/hero_circle_after_img.png";
import heroimg4 from "../assets/images/hero/hero_circle_before_img.png";
import herocard1 from "../assets/images/hero/hero_card_1.png";
import herocard2 from "../assets/images/hero/hero_card_2.png";
import herocard3 from "../assets/images/hero/hero_card_3.png";
import herocardhover1 from "../assets/images/hero/hero_card_hover1.png";
import herocardhover2 from "../assets/images/hero/herocardhover2.png";
import herocardhover3 from "../assets/images/hero/herocardhover3.png";
import heromainimgbg from "../assets/images/hero/hero_bg_small.png";
import { HeroArrow1, HeroArrow2 } from "./common/Icons";

const Hero = () => {
  return (
    <>
      <section className="hero_bg position-relative" id="home">
        <Header />
        <div className="container">
          <div className="row justify-content-center justify-content-md-between">
            <div className="col-sm-10 col-md-6">
              <div
                className="position-relative hero_imgages"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1200"
              >
                <div className="position-absolute hero_arw_1">
                  <HeroArrow1 />
                </div>
                <div className="position-absolute hero_arw_2">
                  <HeroArrow2 />
                </div>
                <img
                  className="position-absolute  hero_transeparent_bg_img"
                  src={heromainimgbg}
                  alt="heromainimgbg"
                />
                <div className="d-flex justify-content-center align-items-start gap-2 gap-sm-3">
                  <img
                    className="hero_rect_before_img scale_img_hover transition_03s"
                    src={heroimg1}
                    alt="heroimg1"
                  />
                  <img
                    className="hero_rect_after_img scale_img_hover transition_03s mt-4 mt-sm-5"
                    src={heroimg2}
                    alt="heroimg2"
                  />
                </div>
                <img
                  className="hero_circle_after_img scale_img_hover transition_03s"
                  src={heroimg3}
                  alt="heroimg3"
                />
                <img
                  className="position-absolute hero_circle_before_img scale_img_hover transition_03s"
                  src={heroimg4}
                  alt="heroimg4"
                />
              </div>
            </div>
            <div className="col-md-5 mt-lg-5 pt-xl-5 mb-5 mt-md-4 text-center text-md-start px-0 px-sm-2 ">
              <div className="d-flex flex-column align-items-lg-start align-items-center">
                <h1
                  className="font-4xl fw_bold white mt-3 mb-0 mb-md-2 order-1 order-md-1"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-aos-duration="1200"
                >
                  Transform Your Mind Body &#38; Health
                </h1>
                <p
                  className="sub_heading white mb-0 order-3 order-md-2 pt-4 d-none d-md-block"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-aos-duration="1200"
                >
                  Heal Your body in 90 days with Noah's proven holistic methods
                </p>
                <a
                  className="hero_btn transition_03s d-inline-block font-lg fw_bold text-decoration-none white order-2 order-md-3"
                  href="https://flemfit.com/checkout"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  data-aos-duration="1200"
                >
                  I'm ready to change
                </a>
              </div>
              <h2 className="heading text-center pt-4 pt-md-0 mt-md-4 my-xxl-5 d-md-none mt-3 mt-md-0">
                What’s included
              </h2>
              <div className="hero_card container w-100 pt-md-0 mt-4 mt-md-0">
                <h2 className="heading text-center mt-md-4 my-xxl-5 d-none d-md-block">
                  What’s included
                </h2>
                <div className="d-flex justify-content-between">
                  <div className="hero_card_parrent d-flex align-items-center position-relative">
                    <a className="w-100" href="#modules">
                      <img
                        className="w-100 hero_card_1_img"
                        src={herocard1}
                        alt="herocard1"
                      />
                      <img
                        className="hero_card_hover w-100  position-absolute"
                        src={herocardhover1}
                        alt="herocardhover1"
                      />
                    </a>
                  </div>
                  <div className="hero_card_parrent position-relative">
                    <a href="#programs">
                      <img
                        className="w-100 hero_card_1_img"
                        src={herocard2}
                        alt="herocard2"
                      />
                      <img
                        className="hero_card_hover w-100  position-absolute"
                        src={herocardhover3}
                        alt="herocardhover3"
                      />
                    </a>
                  </div>
                  <div className="hero_card_parrent position-relative">
                    <a href="#bonuses">
                      <img
                        className="w-100 hero_card_1_img"
                        src={herocard3}
                        alt="herocard3"
                      />
                      <img
                        className="hero_card_hover w-100  position-absolute"
                        src={herocardhover2}
                        alt="herocardhover2"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
