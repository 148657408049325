import React from "react";

const footer = () => {
  const getCurrentYear = new Date().getFullYear();
  return (
    <>
      <section>
        <div className="footer_bg py-5 mt-sm-5">
          <div className="container">
            <div className="d-flex flex-wrap flex-column flex-md-row justify-content-center justify-content-between align-items-md-center  pb-3 pb-lg-0">
              <div className="col-12 col-sm-12 col-md-10 mx-auto">
                <div className="d-flex flex-wrap gap-5 gap-sm-0 justify-content-center justify-content-sm-around ">
                  <a
                    className="font_18 white  fw-normal text-decoration-none footer_hover text_line"
                    href="#testimonys"
                  >
                    Testimonys
                  </a>
                  <a
                    className="font_18 white fw-normal text-decoration-none  footer_hover text_line"
                    href="#modules"
                  >
                    Modules
                  </a>
                  <a
                    className="font_18 white fw-normal text-decoration-none footer_hover text_line "
                    href="#programs"
                  >
                    Programs
                  </a>
                  <a
                    className="font_18 white fw-normal text-decoration-none footer_hover text_line"
                    href="#bonuses"
                  >
                    Bonuses
                  </a>
                  <a
                    className="font_18 white fw-normal text-decoration-none footer_hover text_line"
                    href="#about"
                  >
                    About
                  </a>
                  <a
                    className="font_18 white fw-normal text-center text-decoration-none footer_hover footer_underline text_line"
                    href="#my_masters"
                  >
                    My Masters
                  </a>
                </div>
              </div>
              <div className="text-center mx-auto mx-sm-0 mt-5 pt-5 pt-sm-0">
                <a href="#home">
                  <button className="back-top-btn  fw_bold text-decoration-none ">
                    Scroll UP
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-7 col-md-12">
            <h3 className="text-center copyright-text-color font-xsm fw-normal pb-4  py-sm-4 mb-0">
              All right Rights reserved @<span>{getCurrentYear}</span> Noah
              Fleming Industries
            </h3>
          </div>
        </div>
      </section>
    </>
  );
};

export default footer;
