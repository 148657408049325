import "./App.css";
import "./assets/css/navbar.css";
import Home from "./views/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "./components/common/Loader";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import Aos from "aos";

function App() {
  const [loading, setLoading] = useState(true);
  Aos.init({
    once: true,
    duration: 500,
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, [loading]);
  return <>{loading ? <Loader /> : <Home />}</>;
}

export default App;
