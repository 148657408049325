import React from "react";
import Hero from "../components/Hero";
import Healers from "../components/Healers ";
import Trainer from "../components/Trainer";
import Footer from "../components/Footer";
import About from "../components/About";
import AboutCourse from "../components/AboutCourse";
import Bonuses from "../components/Bonuses";
import ModuleSlider from "../components/ModuleSlider";
import TribeTestomonys from "../components/TribeTestomonys";
import Programs from "../components/Programs";

const Home = () => {
  return (
    <>
      <Hero />
      <TribeTestomonys />
      <ModuleSlider />
      <Programs />
      <Bonuses />
      <AboutCourse />
      <div className="text-center mb-4 pb-3">
        <a
          className="hero_btn transition_03s d-inline-block d-sm-none font-lg fw_bold text-decoration-none white text-nowrap px-4 custom_btn_height"
          href="https://flemfit.com/checkout"
        >
          Yes I'm ready to change! Get Now
        </a>
      </div>
      {/* <ModuleSlider /> */}
      <About />
      {/* <div className="text-center"> 
        <a
          className="hero_btn transition_03s d-inline-block d-sm-none font-lg fw_bold text-decoration-none white mt-3 mb-4 px-4 custom_btn_height"
          href="#programs"
        >
          Yes I'm ready to change! Get Now
        </a>
      </div> */}
      <Healers />
      <Trainer />
      <Footer />
    </>
  );
};

export default Home;
