import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { programsData } from "./Helper";
import { Bonusesshopimg } from "./common/Icons";
import { useInView } from "react-intersection-observer";

const Programs = () => {
  const [count, setCount] = useState(0);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const sliderRef3 = useRef(null);

  useEffect(() => {
    if (inView) {
      sliderRef3.current.slickPlay();
    } else {
      sliderRef3.current.slickPause();
    }
  }, [inView]);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    afterChange: (current) => setCount(current),
  };

  return (
    <>
      <section className="py-xl-5 mt-xxl-0" id="programs" ref={ref}>
        <div className="container ">
          <h2
            className="heading fw_bold text-uppercase text-center mt-5 mt-md-0 mb-4 mb-sm-5 pb-lg-5 "
            data-aos="fade-in"
          >
            PROGRAMS
          </h2>
          <div className="row position-relative">
            <div className="number font-lg fw-normal white">
              <span className="font-lg fw-normal sky-blue ">{count + 1}</span> /
              7
            </div>
            <div className="col-12" data-aos="fade-out">
              <Slider
                ref={sliderRef3}
                {...settings}
                className="w-100 programs-slider pt-5 pt-sm-4"
              >
                {programsData.map((obj, index) => (
                  <div
                    className="bounses-slider-padding  p_sm_custom  mt-xl-3"
                    key={index}
                  >
                    <div className="programs-slider-card mx-auto position-relative z-index-10 ">
                      <div className="d-flex flex-column flex-md-row align-items-center gap-4 gap-md-0 gap-lg-5">
                        <div className="col-11 col-sm-9 col-md-5 col-xl-5">
                          <div
                            className="programs_card_bg mx-auto"
                            style={{ background: obj.programscolor }}
                          >
                            <div className="d-flex align-items-center w-100">
                              <img
                                className="w-100 scale_img_hover bounses-img mx-auto"
                                src={obj.bonusImg}
                                alt="bonuses-slider-images"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-11 col-sm-9 col-md-7 col-lg-6   w_xs_80 ps-md-4 ps-lg-0 pt-2 pt-md-0 pt-md-0 ">
                          <div className="d-flex flex-column justify-content-between min_height610 py-md-3 py-xl-4">
                            <div>
                              <h3 className="sub_heading sky-blue fw_bold mb-0 fs_18">
                                {obj.programHeading}
                              </h3>
                            </div>
                            <div>
                              {obj.para.map((obj, index) => (
                                <p
                                  className="para fs_14 fs_14 white opacity_07 pt-2 pt-lg-3 pt-xxl-4  mb-0"
                                  key={index}
                                >
                                  {obj.text}
                                </p>
                              ))}
                              <h5 className="sub_heading fs_24 white fw_bold pt-4 pb-3 py-sm-4 mt-md-4 mb-0">
                                {obj.programPrice}
                              </h5>
                            </div>
                            <div>
                              <a
                                className="Slider_btn transition_03s d-inline-block text-center font-lg font-18 fw_bold text-decoration-none white me-5"
                                href="https://flemfit.com/checkout"
                              >
                                <Bonusesshopimg />
                                YES! I WANT INSTANT ACCESS NOW
                              </a>
                              <p className="font-sm white fw-normal pt-2 mt-1 opacity_07 mb-0">
                                *Get full package for discounted price
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Programs;
