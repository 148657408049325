import React from "react";
import ReactPlayer from "react-player";

const AboutCourse = () => {
  return (
    <>
      <div className="container-fluid container-lg mb-0 mt-5 mb-sm-5 py-md-5 px-0 px-lg-auto">
        <div className="row justify-content-center mx-0">
          <div className="col-md-12 pt-xxl-2 px-0 px-lg-3">
            <h2
              className="heading white text-center mb-md-3 pb-4"
              data-aos="fade-in"
            >
              ABOUT &nbsp;THIS&nbsp; COURSE
            </h2>
            <ReactPlayer
              className="w-100 about_video"
              url="https://www.youtube.com/embed/MB-oZ_xc5O8"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCourse;
