import React from "react";
import line2 from "../assets/images/about-course/line.png";
import line from "../assets/images/about-course/line2.png";
import { aboutText } from "./Helper";
const About = () => {
  return (
    <div
      className="bg_dark position-relative py-sm-5 pt-5 overflow-hidden mt-lg-5"
      id="about"
    >
      <div className="position-absolute Line_big d-none d-sm-block  z-index-0">
        <img className="w-100 h-100" src={line2} alt="line" />
      </div>
      <div className="container-fluid container-md  position-relative text-white">
        <div className="position-absolute z-index-0 line_small d-sm-none ">
          <img className="w-100 h-100" src={line} alt="line" />
        </div>
        <div className="position-relative z-index-2 py-md-5 px-sm-2 px-md-0 ">
          <h2
            className="font-3xl fw-bold pb-4 py-sm-4 pb-sm-0 mb-0 text-white"
            data-aos="fade-in"
          >
            ABOUT NOAH
          </h2>
          {aboutText.map((obj, index) => (
            <div
              key={index}
              className={`row justify-content-between  ${
                index === 1 ? "py-4" : ""
              }`}
            >
              <div
                className={`col-6 col-lg-7 d-flex flex-column justify-content-center ${
                  index === 1 ? "order-2" : `order-1 pe-xl-5`
                }`}
                data-aos="fade-in"
              >
                <div key={index} className={`${index === 1 ? "" : "pe-xxl-5"}`}>
                  <p className="font-lg fw-normal opacity-75">{obj.para1}</p>
                  <p className="font-lg fw-normal opacity-75">{obj.para2}</p>
                </div>
              </div>
              <div
                className={`col-6 col-lg-5 d-flex flex-column justify-content-center ${
                  index === 1 ? "order-1 mx-md-auto" : "order-2"
                }`}
                data-aos="fade-out"
              >
                <img
                  className={`${index === 1 ? "mx-auto" : ""} w-100 about-img`}
                  src={obj.imgUrl}
                  alt="aboutImg"
                />
              </div>
            </div>
          ))}
        </div>
        <div className="text-center py-5 position-relative z_index_2 my-lg-4">
          <a href="https://flemfit.com/checkout">
            <button className="buy_now_btn fw-bold">LEARN FROM NOAH!</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
