import { useState, useEffect } from "react";
import Slider from "react-slick";
// import { TestimonysVideoPlayIcon } from "./common/Icons";
import playIcon from "../assets/images/testimonyals/play-icon.png";

const TribeCards = ({ obj }) => {
  const [imageUrl, setImageUrl] = useState(obj.testimonysImg[0]);
  const [isMouserOnImage, setIsMouserOnImage] = useState(false);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
  };

  const changeImageHandler = (value) => {
    setImageUrl(value.testimonysImg[1]);
    setTimeout(() => {
      setImageUrl(value.testimonysImg[2]);
    }, [1000]);
  };

  useEffect(() => {
    if (obj.testimonysImg[2] === imageUrl) {
      if (isMouserOnImage === true) {
        setTimeout(() => {
          setImageUrl(obj.testimonysImg[0]);
        }, [1000]);
        setTimeout(() => {
          changeImageHandler(obj);
        }, [2000]);
      } else {
        setImageUrl(obj.testimonysImg[0]);
      }
    }
  }, [imageUrl, isMouserOnImage]);
  return (
    <>
      <div className="d-none d-md-block">
        {obj && obj.testimonysImg && obj.testimonysImg.length === 3 ? (
          <img
            className="w-100 transition_03s"
            src={imageUrl}
            alt="testimonysImg"
            onMouseOver={() => {
              setIsMouserOnImage(true);
              changeImageHandler(obj);
            }}
            onMouseLeave={() => {
              setIsMouserOnImage(false);
            }}
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-delay="1000"
          />
        ) : (
          <img className="w-100" src={obj.testimonysImg} alt="testimonysImg" />
        )}
      </div>
      <div className="d-md-none position-relative">
        <Slider {...settings}>
          {obj.testimonysImg.map((item, index) => (
            <div key={index}>
              <img className="w-100" src={item} alt="testimonysImg" />
            </div>
          ))}
        </Slider>
      </div>
      <h2 className="mt-4 pt-2 font-lg white fw_bold text-uppercase mb-2 ">
        {obj.title}
      </h2>
      <p className="font-sm white opacity-75">{obj.desc}</p>
    </>
  );
};

export default TribeCards;
