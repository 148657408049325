import React from "react";
import brain from "../assets/images/healers/svg/brain-img.svg";
import { healerData } from "./Helper";
const Healers = () => {
  return (
    <section className="pt-md-5">
      <div className="container pt-md-5 mt-md-5">
        <div
          className="d-flex flex-column flex-sm-row align-items-md-center pt-1 pb-0  pb-sm-5 mb-xl-2"
          data-aos="fade-in"
        >
          <div className="pe-3 brain-img">
            <img className="pb-3 pb-sm-0 w-100 " src={brain} alt="brain-img" />
          </div>
          <div className="col-lg-11">
            <h4 className="heading fw_bold text-uppercase">
              Healers Noah Has Learned from and / or worked with
            </h4>
          </div>
        </div>
        <div className="row justify-content-center justify-content-lg-start ">
          {healerData.map((val, index) => {
            return (
              <div
                className={`col-12 col-lg-6 pb-md-5 pb-4 mb-3 mb-md-2 ${
                  index === 0
                    ? "order-0"
                    : `order-lg-1 ${
                        index === 1
                          ? "order-1"
                          : `order-lg-2 ${
                              index === 2
                                ? "order-0"
                                : `order-lg-3 ${
                                    index === 4
                                      ? "order-0"
                                      : `order-lg-3 ${
                                          index === 3
                                            ? "order-2"
                                            : `order-lg-5 ${
                                                index === 5
                                                  ? "order-2"
                                                  : `order-lg-6`
                                              }`
                                        }`
                                  }`
                            }`
                      }`
                }`}
                key={index}
                data-aos="fade-out"
              >
                <div
                  className={`d-flex justify-content-center justify-content-sm-evenly justify-content-lg-start`}
                >
                  <div
                    className={`trainers-img  ${
                      index === 1 || index === 2 || index === 5
                        ? "order_2"
                        : "order_1"
                    }
                     `}
                  >
                    <img
                      className="w-100 border-radius50 d-none  d-lg-block"
                      src={val.imgUrl}
                      alt=""
                    />
                    <img
                      className="w-100 border-radius50 max-height-143 d-lg-none"
                      src={val.imgUrl2}
                      alt=""
                    />
                  </div>
                  <div
                    className={` ${
                      index === 1 || index === 2 || index === 5
                        ? "order_1 pe-3 pe-md-4 pe-lg-0 pt-2"
                        : "order_2 ps-3 ps-md-4 ps-lg-0 pt-2"
                    } ps-lg-2 ps-xl-4 pt-1`}
                  >
                    <h2 className="font-lg sky-blue fw_bold text-uppercase pb-1 pb-lg-0 pb-xxl-1 mb-lg-1 mb-xxl-2">
                      {val.heading}
                    </h2>
                    {val.para.map((obj, index) => (
                      <p
                        className={`sub_para mb-0 white  text-uppercase ${
                          obj.text ==
                          "Coached 100s of elitle  level powerlifters"
                            ? "pt-2 pb-4"
                            : ""
                        }`}
                        key={index}
                      >
                        {obj.text}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Healers;
