import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Slider from "react-slick";
import testimonyslogo from "../assets/images/svg/testmonys_logo.svg";
import { testimonysData } from "./Helper";
import TribeTestomonysContent from "./TribeTestomonysContent";
import { useInView } from "react-intersection-observer";

const TribeTestomonys = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      sliderRef.current.slickPlay();
    } else {
      sliderRef.current.slickPause();
    }
  }, [inView]);

  const sliderRef = useRef();

  const settings = {
    dots: false,
    arrows: false,
    autoplaySpeed: 5000,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "ease-in-out",
    infinite: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [leftMargin, setLeftMargin] = useState(0);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setLeftMargin(containerRef.current.getBoundingClientRect().left);
    }
    window.addEventListener("resize", () => {
      if (containerRef.current) {
        setLeftMargin(containerRef.current.getBoundingClientRect().left);
      }
    });
  }, [leftMargin]);

  return (
    <>
      <section className="testynomys_section" id="testimonys" ref={ref}>
        <div className="container pt-5" ref={containerRef}>
          <div
            className="d-flex align-items-center module_section_heading mb-2 pb-1 mb-sm-4 pb-lg-3"
            data-aos="fade-out"
          >
            <img
              className="testimonyslogo"
              src={testimonyslogo}
              alt="testimonyslogo"
            />
            <div className="ps-sm-3">
              <h2 className="heading mb-1 mb-md-2"> The Tribe Testimonys</h2>
              <p className="para white opacity-75 mb-0">
                The results of Noah’s framework being put into action
              </p>
            </div>
          </div>
        </div>
        <div
          className=" position-relative slider_container"
          style={{ marginLeft: `${leftMargin + 16}px` }}
        >
          <Slider {...settings} ref={sliderRef}>
            {testimonysData.map((obj, index) => (
              <TribeTestomonysContent obj={obj} key={index} />
            ))}
          </Slider>
        </div>
        <div className="text-center py-5 position-relative z_index_2 ">
          <a href="https://flemfit.com/checkout">
            <button className="buy_now_btn fw-bold font_20">
              I WANT INSTANT ACCESS NOW!
            </button>
          </a>
        </div>
      </section>
    </>
  );
};

export default TribeTestomonys;
