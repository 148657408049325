import { useState } from "react";
import TribeCards from "./TribeCards";
// import { TestimonysVideoPlayIcon } from "./common/Icons";
import TribeModal from "./TribeModal";
import playIcon from "../assets/images/testimonyals/play-icon.png";
import { TestimonysVideoPlayIcon } from "./common/Icons";

const TribeTestomonysContent = ({ obj }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };

  const handleShow = () => {
    setShow(true);
    setLoading(true);
  };

  return (
    <div className="px-4 position-relative" data-aos="fade-in">
      <div>
        <div onClick={() => handleShow()}>
          <div className="position-absolute cursor_pointer play_icon end-0 mt-2 pt-1 pe-2 me-4 modal_play_icon">
            <h2 className="font-md fw-bold text-white click_me d-md-none text-uppercase">
              transformation video
            </h2>
            <img
              className="w-100 max-width66 ms-auto "
              src={playIcon}
              alt="playIcon"
            />
          </div>
        </div>
        <TribeCards obj={obj} />
        <TribeModal
          handleClose={handleClose}
          show={show}
          videoUrl={obj.videoLink}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};

export default TribeTestomonysContent;
